<template>
  <div>
    <van-form colon label-width="120" input-align="right" validate-trigger="onChange" @submit="onSubmit">
      <van-field
        v-model="form.consignee"
        name="consignee"
        label="收货人"
        placeholder="请输入收货人姓名"
        :rules="[{ required: true, message: '请填写收货人姓名' }]"
      />
      <van-field
        v-model="form.moblie"
        name="moblie"
        label="电话号码"
        placeholder="电话号码"
        :rules="[{ required: true, message: '请填写手机号或座机号' }]"
      />
      <address-item
        :dpmc="form.dpmc"
        :dcmc="form.dcmc"
        :ddmc="form.ddmc"
        :address="form.area"
        @addressChange="areaConfirm"
      />
      <van-field v-model="form.xxdz" rows="5" autosize input-align="left"
                 type="textarea" name="xxdz"
                 maxlength="100"
                 placeholder="请填写详细地址"
      />
      <van-field center label="设置为默认地址" name="isdefault">
        <template #input>
          <van-switch v-model="form.isdefault" size="20" />
        </template>
      </van-field>
      <div class="footer">
        <van-button type="info" native-type="submit" round size="small" class="fixedBtn">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
const formObj ={
  id:'',
  consignee: '',
  mobil: '',
  xxdz:'',
  area:'',
  isdefault:true,
  dpmc:'', //省名称
  dpdm:'',//省代码
  dcdm:'',//市代码
  dcmc:'', //市名称
  dddm:'', //县
  ddmc:''
}
export default {
  name:'AddressAdd',
  data() {
    return {
      form:{
        id:'',
        consignee: '',//收货人姓名
        mobil: '',
        xxdz:'',
        area:'',
        isdefault:true,
        dpmc:'', //省名称
        dpdm:'',//省代码
        dcdm:'',//市代码
        dcmc:'', //市名称
        dddm:'', //县
        ddmc:''
      },
      Picker:false,
      areaList:{
        province_list: {
          110000: '北京市',
          120000: '天津市'
        },
        city_list: {
          110100: '北京市',
          110200: '县',
          120100: '天津市',
          120200: '县'
        },
        county_list: {
          110101: '东城区',
          110102: '西城区',
          110105: '朝阳区',
          110106: '丰台区',
          120101: '和平区',
          120102: '河东区',
          120103: '河西区',
          120104: '南开区',
          120105: '河北区'
        }
      }
    }
  },
  mounted(){
    const flag = this.$route.query.type
    if(flag==1){
      //修改
      const address = JSON.parse(localStorage.getItem('address'))
      this.form = address
    }else{
      this.form=formObj
    }
    // this.$store.dispatch('user/refreshToken',{"token": this.$store.getters.refreshToken} )
  },
  methods: {
    onSubmit(values) {
      let params={
        ...values,
        ...this.form
      }
      params.isdefault==true? params.isdefault=1 :params.isdefault=0
      if(!this.$route.query.type){
        userModel.addressAdd(params).then(()=>{
          this.$toast({message:'添加成功！'})
          this.$router.back()
        })
      }else{
        userModel.addressEdit(params).then(()=>{
          this.$toast({message:'修改成功！'})
          this.$router.back()
        })
      }
    },
    areaConfirm(value){
      this.form.dpmc =value[0].name
      this.form.dpdm =value[0].code
      this.form.dcmc =value[1].name
      this.form.dcdm =value[1].code
      this.form.ddmc =value[2] ? value[2].name : ''
      this.form.dddm =value[2] ? value[2].code : ''
      this.form.area = value[0].name+'/'+value[1].name+(value[2] ? '/'+value[2].name : '')
    }
  }
}
</script>
<style scoped>
.footer{
  background: #FFFFFF;
}
</style>
